function urlencode(obj: Map<string, any>): string {
	var str = [];
	var encode = encodeURIComponent;
	obj.forEach((val, key, map) => str.push(encode(key) + '=' + encode(val + '')));
	return str.join('&');
}

export default function (callback: any) {
	return function (event: any) {
		let form: HTMLFormElement = event.target;
		let elements = form.elements;
		let result = new Map<string, any>();
		for (let i = 0; i < elements.length; i++) {
			let el: any = elements.item(i);
			if (el.name) result.set(el.name, el.value);
		}
		let queryStr = urlencode(result);
		event.preventDefault();
		callback(result, queryStr);
	}
}