<script>
  import { createEventDispatcher, onMount } from "svelte";
  import doFormSubmit from "./../util/form-submit.ts";
  import getParams from "./../util/hash-params.ts";
  import doNavigate from "./../util/navigate.ts";

  export let navigate = true;
  export let store = null;

  let dispatch = createEventDispatcher();

  const minLength = 2;
  let query = "";
  let pattern = ".{" + minLength + ",}";
  let minLengthError = "Enter at least " + minLength + " characters";
  let search = Promise.resolve();

  function makeSearchRequest() {
    return function() {
      if (!query || query.length < minLength)
        return Promise.reject(minLengthError);
      return store.search({ query }).then(result => store.state.searchResults);
    };
  }

  function doSearch() {
    if (!navigate) {
      search = makeSearchRequest();
      window.location.hash = "q=" + query;
      dispatch("submit", {
        promise: search
      });
    } else doNavigate("search/", "q=" + query);
  }

  /* On load */
  if (!navigate) {
    var handler = () => {
      var params = getParams();
      if (!!params.q) {
        query = params.q;
        doSearch();
      }
    };
    onMount(handler);
    window.addEventListener("popstate", handler);
  }

  /* Form submit */
  function onSearch(event) {
    doFormSubmit(function(params, queryStr) {
      query = params.get("q");
      doSearch();
    })(event);
  }
</script>

<form action="/search/" on:submit={onSearch} class="search-bar">
  <input
    type="text"
    name="q"
    placeholder="Search"
    value={query}
    title={minLengthError}
    {pattern} />
  <button type="submit" class="button is-success">
    <span>⌕</span>
  </button>
</form>
