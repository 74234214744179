<script>
  export let value;
  let css = getCss();
  function getCss() {
    let result = "rating ";
    if (value > 69) {
      result += "is-good";
    } else if (value > 39) {
      result += "is-mixed";
    } else if (value > 0) {
      result += "is-bad";
    } else {
      result += "is-unknown";
    }
    return result;
  }
  $: {
    if (!!value || value == 0) {
      css = getCss();
    }
  }
</script>

<!-- TODO: Add class depending on rating value -->
<div class="rating">
  <h1>
    <slot />
  </h1>
  <p>
    <span class={css}>{value > 0 ? value : '―'}</span>
  </p>
</div>
