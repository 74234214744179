<script>
  import CategoryTop from "./../components/CategoryTop.svelte";
  import SearchBar from "./../components/SearchBar.svelte";
  import Loader from "./../components/Loader.svelte";

  import Navbar from "./../components/Navbar.svelte";
  import Footer from "./../components/Footer.svelte";

  export let store = {};

  let state = {};
  let hello = "world!";
  let loader = (store.notInitialized ? store.load() : Promise.resolve())
    .then(() => store.commit("normalize"))
    .then(() => (state = store.state));
</script>

<Navbar />
<main>
  {#await loader}
    <Loader>Loading data...</Loader>
  {:then}
    <section id="index-landing" class="landing">
      <h1>Welcome to Steam Trends!</h1>
      <h2 class="dim">Your number one source for trending games</h2>
      <p class="no-margin">
        <small class="dim">Tracking {state.count} games and DLCs</small>
      </p>
    </section>
    <section class="splitter dim">
      <span>Scroll down to discover what's hot</span>
    </section>
    {#each state.summary || [] as category}
      {#if category.apps.length > 0}
        <CategoryTop {category} />
      {/if}
    {/each}
  {:catch error}
    <p class="error">Oops! Something went wrong. Try reloading the page.</p>
  {/await}
</main>
<Footer />
