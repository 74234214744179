<script>
  import { beforeUpdate, afterUpdate } from "svelte";
  import lazyload from "./../util/lazyload.js";

  export let id;
  export let capsule = true;
  let imgElement;

  let url = "";
  let className = "banner";
  if (!capsule) {
    className += " is-fullsize";
  }
  $: {
    if (id) {
      url =
        "https://steamcdn-a.akamaihd.net/steam/apps/" +
        id +
        (!capsule ? "/header.jpg" : "/capsule_184x69.jpg");
    }
  }
  // TODO: Uncomment banner loading
  afterUpdate(() => {
  lazyload(imgElement);
  });
</script>

<img
  class={className}
  src="/static/loading.gif"
  data-src={url}
  bind:this={imgElement}
  alt="Game banner image" />
