<script>
  import ResultTableRow from "./ResultTableRow.svelte";

  export let items;
</script>

<table class="results">
  {#each items as item}
    <ResultTableRow {item} />
  {/each}
</table>
