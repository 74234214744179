<script>
  import SearchBar from "./SearchBar.svelte";
  export let search = true;

  function onSearchClick() {
    search = true;
  }
</script>

<navbar id="navbar">
  <ul>
    <li class="logo">
      <!-- TODO: Add logo image -->
      <a href="/" title="Home">
        <img src="/static/logo.png" alt="Logo" />
      </a>
    </li>
    {#if search}
      <li>
        <SearchBar />
      </li>
    {/if}
    <li>
      <a href="/about" title="About">
        <span>About</span>
      </a>
    </li>
  </ul>
</navbar>
