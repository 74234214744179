export default class Store {
	constructor(opts) {
		opts = opts || {};
		this.state = opts.state || {};
		this.getters = opts.getters || {};
		this.mutations = opts.mutations || {};
		this.actions = opts.actions || {};
		var $this = this;
		var commit = function (name, data) {
			$this.mutations[name]($this.state, data);
		};
		Object.keys(this.getters).forEach(function (key, i, a) {
			Object.defineProperty($this, key, {
				get: function () {
					return $this.getters[key](this.state);
				}
			})
		});
		Object.keys(this.actions).forEach(function (key, i, a) {
			$this[key] = function (params) {
				return $this.actions[key]({ commit }, params);
			}
		});
		this.commit = commit;
	}
}