<script>
  import ResultTable from "./ResultTable.svelte";
  import slugify from "./../util/slugify.ts";

  export let category;
  let href = "";
  $: href = !!category.title ? "/rating/" + slugify(category.title) + "/" : "#";
</script>

<section class="category">
  <h1>{category.title}</h1>
  <ResultTable items={category.apps} />
  <a {href} class="view-more splitter">» View more »</a>
</section>
