import Store from "./store";
import { CategorySummary } from '../model/models';
import Client from '../util/client';
import isEmpty from 'underscore-es/isEmpty';
import decode from '../util/htmldecode';


export interface State {
	summary: CategorySummary[],
	count: number
}

const storeOpts: any = {
	state: (<any>window).__INITIAL_STATE__ || {
		summary: [],
		count: 0
	},
	getters: {
		notInitialized (state): boolean {
			return isEmpty(state.summary) && !state.count;
		}
	},
	mutations: {
		setSummary(state, summary: CategorySummary[]): void {
			state.summary = summary;
		},
		setCount(state, count: number): void {
			state.count = count;
		},
		normalize(state): void {
			state.summary.forEach((val, i, a) =>
				val.apps.forEach((val, i, a) =>
				val.name = decode(val.name)));
		}
	},
	actions: {
		async load({ commit }): Promise<void> {
			let [summary, count] = await Promise.all([
				Client.get('/api/rating/summary'),
				Client.get('/api/statistics/count')
			]);
			commit('setSummary', summary.data);
			commit('setCount', count.data);
		}
	}
}

export const store = new Store(storeOpts);
export default store;