<script>
  import Rating from "./Rating.svelte";
  import SteamBanner from "./SteamBanner.svelte";

  export let item;
  let steamUrl = "";
  let appUrl = "";
  let id = "";
  $: {
    id = (item || {}).appId || "";
    steamUrl = "https://store.steampowered.com/app/" + id;
    appUrl = "/app/" + id;
  }
</script>

<tr>
  <td>
    <a href={appUrl}>
      <SteamBanner {id} />
    </a>
  </td>
  <td>
    <h1>
      <a href={appUrl}>{item.name}</a>
    </h1>
    <ul class="genres">
      {#each (item || {}).genres as genre}
        <li>{genre}</li>
      {/each}
    </ul>
    <p>
      <a href={steamUrl} title="View on Steam" target="_blank">
        View on Steam »
      </a>
    </p>
  </td>
  <td>
    <div class="ratings">
      <Rating value={item.current}>RECENT</Rating>
      <Rating value={item.overall}>OVERALL</Rating>
      <Rating value={item.metascore}>METASCORE</Rating>
    </div>
  </td>
</tr>
